<template>
    <mercur-card class="mb-3" :data-test="`productContentSection_${section}`">
        <div class="d-flex align-items-center">
            <h2 class="font-weight-normal mb-4"><span>{{title}}</span></h2>
            <slot name="topActions"></slot>
        </div>
        <div>
            <slot :section="vForm[section]"></slot>
        </div>
        <div class="text-right">
            <mercur-button class="btn" @click="discardChanged()" data-test="productContentSectionDiscardChanges" :disabled="!vForm[section].$anyDirty || isSaving">Discard changes</mercur-button>
            <mercur-button @click="save(section)" class="btn btn-primary" :disabled="isSaving || !vForm[section].$anyDirty || vForm[section].$invalid" data-test="productContentSectionSave">Save changes</mercur-button>
        </div>
    </mercur-card>
</template>
<script>
export default {
    name: 'ProductContentSection',
    props: {
        title: {
            default: null,
            type: String,
        },
        section: {
            required: true,
            type: String,
        },
        vForm: {
            required: false,
            type: Object,
            default: null,
        },
        getProductContentBySection: {
            required: false,
            type: Function,
        },
        marketplaceProductId: {
            required: true,
            type: String,
        },
        productContentId: {
            type: String,
        },
    },
    computed: {
        isSaving () {
            return this.$store.state.productContent.marketplaceProductsSaving[this.marketplaceProductId]
        },
    },
    methods: {
        discardChanged () {
            this.$set(this.vForm[this.section], '$model', this.getProductContentBySection(this.section))
            this.$nextTick(() => {
                this.vForm[this.section].$reset()
            })
        },
        save () {
            const payload = JSON.parse(JSON.stringify(this.vForm[this.section].$model))
            this.$store.dispatch('productContent/saveMarketplaceProductContentSection', {
                marketplaceProductId: this.marketplaceProductId,
                section: this.section,
                payload,
                productContentId: this.productContentId,
            }).then(() => {
                this.$nextTick(() => {
                    this.vForm[this.section].$reset()
                })
            })
        },
    },
}
</script>
