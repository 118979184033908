<template>
    <div class="px-4">
        <div class="row">
            <div class="col-6">
                <product-content-section
                    title="Default product family information"
                    section="general"
                    v-bind="sectionBinding"
                    @discard="$refs.tags.redraw() && $refs.status.redraw()"
                >
                    <template slot-scope="{ section }">
                        <div>
                            Category:
                            <span v-if="!productCategoryId || !categories">-</span>
                            <span v-else>{{categories.find(category => category.productCategoryId === productCategoryId).categoryName}}</span>
                        </div>
                        <mercur-input
                            v-model="section.productName.$model"
                            required
                            data-test="productContentProductName"
                            :class="{'form-invalid': section.productName.$error}">
                            Product name
                            <template slot="note">
                                <span class="form-error" v-if="!section.productName.required">First name is required</span>
                            </template>
                        </mercur-input>
                        <div class="row">
                            <div class="col-7">
                                <pretty-select
                                    v-if="tags"
                                    placeholder="Tags"
                                    :taggable="true"
                                    :multiple="true"
                                    v-model="section.productTags.$model"
                                    :options="tags.filter(tag => section.productTags.$model === null || !section.productTags.$model.includes(tag))"
                                    ref="tags"
                                ></pretty-select>
                            </div>
                            <div class="col-5">
                                <pretty-select
                                    placeholder="Status"
                                    v-model="section.productStatus.$model"
                                    :options="states"
                                    label="title"
                                    :reduce="status => status.value"
                                    ref="status"
                                ></pretty-select>
                            </div>
                        </div>
                        <mercur-textarea class="mt-3" v-model="section.productDescription.$model" data-test="productContentProductDescription">
                            <template slot="label">Description</template>
                        </mercur-textarea>
                    </template>
                </product-content-section>
            </div>

            <div class="col-6">
                <product-content-section
                    title="Product images"
                    section="images"
                    v-bind="sectionBinding"
                >
                    <template>
                        <filedropper
                            :url="uploadUrl"
                            @uploadUpdate="uploadUpdate"
                            :only-allow-images="true"
                        >
                            Drop a file or
                        </filedropper>
                        <transition-group name="list-complete" tag="p">
                            <div v-for="(image, key, index) in images" :key="image.$model.imageId" data-test="productContentImageWrapper" class="product-image">
                                <a :href="getImageUrl(image.$model)" target="_blank" class="product-image__image" :style="{backgroundImage: getImageUrl(image.$model) ? `url(${getImageUrl(image.$model)}` : ''}"></a>
                                <div class="product-image__text">
                                    <span data-test="productContentImageWrapperImageName" class="product-image__filename">{{image.$model.imageFilename}}</span>
                                    <span data-test="productContentImageWrapperImageCaption" class="product-image__caption" v-if="image.$model.imagePrimary">Primary image</span>
                                </div>
                                <mercur-menu class="product-image__actions">
                                    <mercur-button data-test="productContentImageMenuButton" class="btn btn-icon">
                                        <span><i class="fas fa-ellipsis-v"></i></span>
                                    </mercur-button>
                                    <div slot="dropdown">
                                        <mercur-item data-test="productContentImageMenuPrimaryButton" :disabled="image.$model.imagePrimary" @click.native="setImagePrimary(key)"><i class="far fa-image"></i>Make primary image</mercur-item>
                                        <mercur-item data-test="productContentImageMenuMoveUpButton" :disabled="index === 0" @click.native="moveImage('up', key, index)"><i class="fas fa-arrow-up"></i>Move image up</mercur-item>
                                        <mercur-item data-test="productContentImageMenuMoveDownButton" :disabled="index === Object.keys(images).length - 1" @click.native="moveImage('down', key, index)"><i class="fas fa-arrow-down"></i>Move image down</mercur-item>
                                        <hr>
                                        <mercur-item data-test="productContentImageMenuDeleteButton" @click.native="deleteImage(key)"><i class="fas fa-trash"></i>Delete image</mercur-item>
                                    </div>
                                </mercur-menu>
                            </div>
                        </transition-group>
                    </template>
                </product-content-section>
            </div>
            <div class="col-12">
                <product-content-attributes
                    :attribute-configuration="currentAttributes"
                    ></product-content-attributes>
            </div>
            <div class="col-6">
                <product-content-section
                    title="Submission Specifications"
                    section="submissionSpecifications"
                    v-bind="sectionBinding"
                >
                    <div slot="topActions">
                        <mercur-button class="btn btn-yellow ml-3" @click="() => $refs.submissionSpecifications.openNew()">Add new</mercur-button>
                    </div>
                    <template slot-scope="{ section }">
                        <product-content-linked-entries
                            v-if="availableSubmissionSpecifications"
                            :available-entries="availableSubmissionSpecifications"
                            unique-key="submissionSpecificationId"
                            title-key="submissionSpecificationTitle"
                            content-key="submissionSpecificationContent"
                            position-key="submissionSpecificationPosition"
                            v-model="section.productContentSubmissionSpecifications.$model"
                            section-title="Submission specifications"
                            ref="submissionSpecifications"
                        ></product-content-linked-entries>

                    </template>
                </product-content-section>
            </div>
            <div class="col-6">
                <product-content-section
                    title="Frequently asked questions"
                    section="faqs"
                    v-bind="sectionBinding"
                >
                    <div slot="topActions">
                        <mercur-button class="btn btn-yellow ml-3" @click="() => $refs.faqs.openNew()">Add new</mercur-button>
                    </div>
                    <template slot-scope="{ section }">
                        <product-content-linked-entries
                            v-if="availableFaqs"
                            :available-entries="availableFaqs"
                            unique-key="faqId"
                            title-key="faqQuestion"
                            content-key="faqAnswer"
                            position-key="faqPosition"
                            v-model="section.productContentFaq.$model"
                            section-title="Frequently asked questions"
                            ref="faqs"
                        ></product-content-linked-entries>

                    </template>
                </product-content-section>
            </div>
        </div>
    </div>
</template>

<script>
import PrettySelect from '../../components/utils/PrettySelect'
import CONFIG from '@root/config'
import { required } from 'vuelidate/lib/validators'
import ProductContentSection from '../../components/product_content_management/ProductContentSection'
import Filedropper from '../../components/utils/Filedropper'
import ProductContentLinkedEntries from '@platform-frontend-components/linked-entries'
import ProductContentAttributes from '../../components/product_content_management/ProductContentAttributes'
import ProductContentImages from '../../components/product_content_management/ProductContentImages'

export default {
    name: 'ProductContentMarketplaceProductDetail',
    mixins: [ProductContentImages],
    components: { ProductContentAttributes, ProductContentLinkedEntries, Filedropper, ProductContentSection, PrettySelect },
    props: {
        productContent: {
            type: Object,
        },
        productCategoryId: {
            type: String,
        },
        marketplaceProductId: {
            type: String,
            required: true,
        },
        currentAttributes: {
            type: [Object, null],
        },
    },
    data () {
        return {
            form: null,
        }
    },
    validations: {
        form: {
            general: {
                productName: {
                    required,
                },
                productTags: {

                },
                productStatus: {
                    required,
                },
                productDescription: {
                    required,
                },
            },
            images: {
                productContentImages: {
                    $each: { },
                },
            },
            submissionSpecifications: {
                productContentSubmissionSpecifications: {
                    $each: { },
                },
            },
            faqs: {
                productContentFaq: {
                    $each: { },
                },
            },
        },
    },
    computed: {
        categories () {
            return this.$store.state.productContent.categories
        },
        tags () {
            return this.$store.state.productContent.tags
        },
        availableSubmissionSpecifications () {
            return this.$store.state.productContent.submissionSpecifications
        },
        availableFaqs () {
            return this.$store.state.productContent.faqs
        },
        states () {
            return CONFIG.PRODUCT_CONTENT_MANAGEMENT.FILTERS.STATES
        },
        sectionBinding () {
            return {
                vForm: this.$v.form,
                getProductContentBySection: this.getProductContentBySection,
                marketplaceProductId: this.marketplaceProductId,
                productContentId: this.productContent ? this.productContent.productContentId : null,
            }
        },
        uploadUrl () {
            return CONFIG.API.ROUTES.PRODUCT_CONTENT.PRODUCTS.IMAGES.GET_UPLOAD_URLS.replace('{marketplaceProductId}', this.marketplaceProductId)
        },
    },
    watch: {
        productContent: {
            handler () {
                this.$set(this, 'form', {
                    general: this.getProductContentBySection('general'),
                    images: this.getProductContentBySection('images'),
                    submissionSpecifications: this.getProductContentBySection('submissionSpecifications'),
                    faqs: this.getProductContentBySection('faqs'),
                })
                this.$nextTick(() => {
                    this.$v.form.$reset()
                })
            },
            immediate: true,
        },
    },
    methods: {
        getProductContentBySection (section) {
            const fieldNames = Object.keys(this.$v.form[section].$params)

            if (!this.productContent || Object.keys(this.productContent).length === 0) {
                return Object.fromEntries(fieldNames.map(field => [field, null]))
            }
            return JSON.parse(JSON.stringify(Object.fromEntries(Object.entries(this.productContent).filter(([key]) => fieldNames.includes(key)))))
        },
    },
}
</script>

<style lang="scss" scoped>
.product-image {
    margin-bottom: 10px;
    display: flex;
    transition: all .5s ease-out;

    &__image {
        width: 56px;
        height: 56px;
        margin-right: 10px;
        background-color: #888;
        flex: 0 0 56px;
        background-size: cover;
        background-position: center center;
    }

    &__caption {
        display: block;
        color: #888888;
    }

    &__actions {
        margin-left: auto;
    }
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
.list-complete-leave-active {
    position: absolute;
}
</style>
