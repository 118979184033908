import CONFIG from '@root/config'

export default {
    data () {
        return {
            uploads: {},
            imageUrls: {},
        }
    },
    computed: {
        images () {
            if (!this.form.images.productContentImages) {
                return null
            }
            return Object.fromEntries(Object.entries(this.$v.form.images.productContentImages.$each.$iter).sort(([, a], [, b]) => a.$model.imagePosition - b.$model.imagePosition))
        },
    },
    methods: {
        uploadUpdate (params) {
            const { type, file, percentCompleted } = params
            const fileId = file.fileUploadId
            if (type === 'start') {
                this.$set(this.uploads, fileId, {})
                const newImagePosition = Math.max(...Object.values(this.form.images.productContentImages).map(o => o.imagePosition), 0) + 1

                if (Array.isArray(this.form.images.productContentImages)) {
                    this.$set(this.form.images, 'productContentImages', {})
                }

                this.$set(this.$v.form.images.productContentImages.$model, fileId, {
                    imageId: this.$uuid.v4(),
                    bucketName: file.bucketName,
                    imageTitle: file.originalFilename,
                    objectName: file.objectName,
                    imagePrimary: Object.keys(this.form.images.productContentImages).length === 0,
                    imageFilename: file.originalFilename,
                    imageLanguage: 'en',
                    imagePosition: newImagePosition,
                    productContentId: this.productContentId,
                    percentCompleted,
                })
                this.$v.form.images.productContentImages.$touch()
                return
            }

            this.$set(this.form.images.productContentImages[fileId], 'percentCompleted', percentCompleted)
            if (file.uploadDetail && file.uploadDetail.objectName) {
                this.$set(this.form.images.productContentImages[fileId], 'objectName', file.uploadDetail.objectName)
            }
            if (file.bucketName) {
                this.$set(this.form.images.productContentImages[fileId], 'bucketName', file.bucketName)
            }
        },
        getImageUrl ({ percentCompleted, objectName }) {
            if (typeof percentCompleted !== 'undefined' && percentCompleted !== 100) {
                return ''
            }
            if (this.imageUrls[objectName] || this.imageUrls[objectName] === null) {
                return this.imageUrls[objectName]
            }

            const url = CONFIG.API.ROUTES.PRODUCT_CONTENT.PRODUCTS.IMAGES.GET_PREVIEW_URLS.replace('{marketplaceProductId}', this.marketplaceProductId)

            this.$set(this.imageUrls, objectName, null)
            this.$api.post(url, {
                files: [objectName],
            }).then(({ data }) => {
                this.$set(this.imageUrls, objectName, data.find(result => result.objectName === objectName).url)
            }).catch(({ err }) => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            })
        },
        moveImage (direction, key, index) {
            const self = this.images[key]
            const other = this.images[Object.keys(this.images)[index + (direction === 'up' ? -1 : 1)]]
            const storedImagePosition = other.$model.imagePosition
            this.$set(other.$model, 'imagePosition', self.$model.imagePosition)
            this.$set(self.$model, 'imagePosition', storedImagePosition)
            this.$v.form.images.$touch()
        },
        setImagePrimary (newPrimaryKey) {
            for (let key in this.images) {
                if (!this.images.hasOwnProperty(key)) {
                    continue
                }
                this.$set(this.images[key].$model, 'imagePrimary', newPrimaryKey === key)
            }
            this.$v.form.images.$touch()
        },
        deleteImage (key) {
            this.$delete(this.$v.form.images.productContentImages.$model, key)

            this.$v.form.images.$touch()
        },
    },
}
