<template>
    <mercur-card class="product-content-section mb-3">
        <h2 class="font-weight-normal mb-4"><span>Product specifications</span></h2>
        <div v-if="attributes">
            <div data-test="productContentAttributesList" v-for="(options, attributeName) in attributes" class="product-content-section__attribute" :key="attributeName">
                <div class="product-content-section__attribute-title">{{attributeName | beautify}}</div>
                <span v-for="(attributeOption, key) in options" :key="key" class="product-content-section__attribute-option">
                    {{attributeOption.option | beautify}}{{attributeOption.postfix}}
                </span>
            </div>
        </div>
    </mercur-card>
</template>

<script>
export default {
    name: 'ProductContentAttributes',
    props: {
        attributeConfiguration: {
            required: true,
        },
        availableCountries: {
            type: [Array, null],
        },
    },
    computed: {
        attributes () {
            if (!this.attributeConfiguration) {
                return null
            }
            const sortedEntries = Object.entries(this.attributeConfiguration.selectedAttributes).sort((a, b) => a[0].localeCompare(b[0]))
            return Object.fromEntries(sortedEntries.map(([attributeName, options]) => {
                options.sort((optionA, optionB) => optionA.option.localeCompare(optionB.option))
                return [attributeName, options]
            }))
        },
    },
}
</script>
